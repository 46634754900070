<template>
  <div class="divBox">
    <v-list
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :exportParams="exportParams"
      ref="table"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <!-- <v-button text="新增" @click="handleAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <area-select
          @changeModel="changeModel"
          specifyCollectionTypes="1"
          :disabled="true"
        />
        <!-- <community-select v-model="searchParam.communityId" label="选择园区" /> -->

        <v-select
          clearable
          :options="orderStatusOptions"
          v-model="searchParam.status"
          label="选择订单状态"
        />

        <v-input label="订单编号" v-model="searchParam.orderSn" clearable />
        <v-input label="用户名" v-model="searchParam.userName" clearable />
        <v-input label="联系电话" v-model="searchParam.mobileNum" clearable />
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <div style="font-size: 14px; margin-right: 10px">下单时间</div>
          <v-date-picker
            v-model="datetimerange"
            type="datetimerange"
            clearable
            @change="changeDate"
          ></v-date-picker>
        </div>
      </template>
    </v-list>
  </div>
</template>

<script>
import { placeOrderListPagingUrl, exportOrderUrl } from "./api.js";
import { getOption, createImgVNode } from "@/utils/utils.js";
import { placeOrderStatus } from "./map.js";
import {
  delPlaceTypeUrl,
  placeOrderCancelUrl,
  PointsMallOrderListUrl,
} from "./api";
import { orderStatus } from "./map";

export default {
  name: "pointsMallOrderList",
  data() {
    let _this = this;
    return {
      searchParam: {
        spaceIds: null,
        status: null,
        orderSn: null,
        userName: null,
        mobileNum: null,
        startTime: null,
        endTime: null,
      },
      exportParams: {
        url: exportOrderUrl,
        params: {},
      },
      datetimerange: [],
      orderStatusOptions: orderStatus,

      dialogFormVisible: false,
      choosedDataRow: {},
      temp: {
        scheduleList: [],
      },

      tableUrl: PointsMallOrderListUrl,
      headers: [
        // {
        //   prop: "id",
        //   label: "订单Id",
        // },
        {
          prop: "orderSn",
          label: "订单编号",
        },
        {
          prop: "communityName",
          label: "订单所属园区",
        },
        {
          prop: "changeTitle",
          label: "商品名称",
        },
        {
          prop: "skuTitle",
          label: "规格名称",
        },
        {
          prop: "skuImageUrl",
          label: "商品图片",
          align: "center",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "receiverName",
          label: "兑换用户",
        },
        {
          prop: "receiverPhone",
          label: "联系电话",
        },
        {
          prop: "exchangeGoodsNum",
          label: "兑换数量",
          align: "center",
        },
        {
          prop: "pointsChangeNum",
          label: "消耗积分",
          align: "center",
        },
        {
          prop: "status",
          label: "状态",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < orderStatus.length; i++) {
              if (row[prop] == orderStatus[i].value) {
                return orderStatus[i].label;
              }
            }
          },
        },
        {
          prop: "insertTime",
          label: "下单时间",
        },
        {
          prop: "completedTime",
          label: "完成时间",
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.exportParams.params = this.searchParam;
  },
  computed: {},
  methods: {
    changeModel(value) {
      this.searchParam.spaceIds = value.spaceId;
    },
    changeDate() {
      if (this.datetimerange && this.datetimerange.length == 2) {
        this.searchParam.startTime = this.datetimerange[0].format(
          "yyyy-MM-dd HH:mm:ss"
        );
        this.searchParam.endTime = this.datetimerange[1].format(
          "yyyy-MM-dd HH:mm:ss"
        );
      } else {
        this.searchParam.startTime = null;
        this.searchParam.endTime = null;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
